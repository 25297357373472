import { Controller } from "stimulus";

export default class extends Controller {
  cartCategories = [];
  cartItems = [[], [], [], []];
  cartItemNames = [[], [], [], []];
  cartPrices = [[], [], [], []];

  connect() {
    this.updateCartSummary();
    this.clearCart();
  }

  addToCart(event) {
    var data = event.currentTarget.dataset;
    var category = parseInt(data.category);
    var itemDetail = parseInt(data.itemDetail);
    var name = data.name;
    var price = parseInt(data.price);
    var carryOn = this.checkRules(category, itemDetail);
    if (carryOn) {
      this.addingToCart(category, itemDetail, name, price);
    } else {
      alert(
        "Duplicate entries not allowed, consultation cannot be booked with tests"
      );
    }
    this.updateCartDisplay();
    this.updateCartSummary();
    this.updateSearchBox(category);
  }

  removeFromCart(event) {
    var data = event.currentTarget.dataset;
    var category = parseInt(data.category);
    var index = parseInt(data.index);
    if (this.cartItems[category].length === 1) {
      this.cartCategories = this.cartCategories.filter(cat => cat !== category);
    }
    this.cartItems[category].splice(index, 1);
    this.cartItemNames[category].splice(index, 1);
    this.cartPrices[category].splice(index, 1);
    this.updateCartDisplay();
    this.updateCartSummary();
  }

  clearCart() {
    this.cartCategories = [];
    this.cartItems = [[], [], [], []];
    this.cartItemNames = [[], [], [], []];
    this.cartPrices = [[], [], [], []];
    this.updateCartDisplay();
    this.updateCartSummary();
  }

  updateCartDisplay() {
    var cartShow = "";
    this.cartItems.map((dataArr, index) => {
      dataArr.map((itemCode, ind) => {
        var insertDisplay =
          `<tr><td>${this.cartItemNames[index][ind]}</td>` +
          `<td>${this.cartPrices[index][ind]}</td>`;
        insertDisplay =
          insertDisplay +
          `<td><button class='btn btn-link' data-category=${index} data-index=${ind} ` +
          `data-action="cart#removeFromCart"><i class='far fa-trash-alt' ` +
          "style='color: red'></i></button></td>" +
          "</tr>";
        cartShow += insertDisplay;
      });
    });
    document.getElementById("show-cart-items").innerHTML = cartShow;
    this.updateFormEntries();
    this.updateCheckoutDisplay();
  }

  updateCheckoutDisplay() {
    if (this.cartCategories.length === 0) {
      document.getElementById("show-checkout-button").innerHTML = "";
    } else {
      document.getElementById("show-checkout-button").innerHTML =
        "<button type='submit' class='btn btn-sm btn-success'>" +
        "CheckOut <i class='fas fa-cash-register'></i></button>";
    }
  }

  updateFormEntries() {
    var counter = 0;
    var formFields = "";
    this.cartItems.map((ItemIds, category) => {
      ItemIds.map((id, index) => {
        formFields += this.itemFormField(id, counter, "item_detail");
        formFields += this.itemFormField(category + 1, counter, "category");
        formFields += this.itemFormField(
          this.cartItemNames[category][index],
          counter,
          "item_name"
        );
        formFields += this.itemFormField(
          this.cartPrices[category][index],
          counter,
          "item_price"
        );
        counter += 1;
      });
    });
    document.getElementById("form-hidden-fields").innerHTML = formFields;
    var tloc = document.getElementById("tloc")
      ? `tloc=${document.getElementById("tloc").value}&`
      : "tloc=&";
    var tdoc =
      document.getElementById("tdoc").value !== ""
        ? `tdoc=${document.getElementById("tdoc").value}&`
        : "tdoc=&";
    var tmso =
      document.getElementById("tmso") &&
      document.getElementById("tmso").value !== ""
        ? `tmso=${document.getElementById("tmso").value}&`
        : "tsmo=&";
    var actionValue =
      document.getElementById("cart-submission-form").action.split("?")[0] +
      `?${tloc}${tdoc}${tmso}`;
    document.getElementById("cart-submission-form").action = actionValue;
  }

  itemFormField(value, counter, fieldName) {
    return (
      `<input type="hidden" value="${value}" ` +
      `name="cart[cart_items_attributes][${counter}][${fieldName}]" />`
    );
  }

  updateCartSummary() {
    var cartSum = 0;
    var cartQty = 0;
    this.cartPrices.map(categoryArray => {
      cartQty += categoryArray.length;
      cartSum = categoryArray.reduce((total, price) => price + total, cartSum);
    });
    document.getElementById("cart-summary").innerHTML =
      `<p>You selected <strong>${cartQty}</strong> tests <br/>` +
      `Your subtotal is <strong>${cartSum}<strong></p>`;
  }

  addingToCart(category, itemDetail, name, price) {
    this.cartCategories.includes(category)
      ? ""
      : this.cartCategories.push(category);
    this.cartItems[category - 1].push(itemDetail);
    this.cartItemNames[category - 1].push(name);
    this.cartPrices[category - 1].push(price);
  }

  checkRules(category, itemDetail) {
    var canContinue = true;
    // Rule 1. Consultation can only be booked as a unique entity
    if (category === 3 && this.cartCategories.length > 0) {
      canContinue = canContinue & false;
    }
    if (category !== 3 && this.cartCategories.includes(3)) {
      canContinue = canContinue & false;
    }
    // Rule 2. Rebooking of same test is not allowed
    if (this.cartItems[category - 1]?.includes(itemDetail)) {
      canContinue = canContinue & false;
    }
    return canContinue;
  }

  updateSearchBox(category) {
    var options = ["path_data", "xray_data", "consult_data", "profile_data"];
    if (options[category - 1] !== "consult_data") {
      var element = document
        .getElementById(`${options[category - 1]}_search_test_name`)
        .getElementsByTagName("input")[0];
      element.value = "";
      element.focus();
      var event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      element.dispatchEvent(event);
    }
    return;
  }
}
