import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["doctor"];
  connect() {}
  update_with_fetched_data(fetchedData) {
    var directSection = document.getElementById(this.data.get("tag"));
    directSection.innerHTML = fetchedData;
  }
  get_data() {
    var tloc = document.getElementById("tloc")
      ? document.getElementById("tloc").value
      : "";
    var tdoc = this.doctorTarget.value;
    if (tdoc !== "") {
      fetch(`${this.data.get("url")}?tloc=${tloc}&tdoc=${tdoc}`, {
        method: "GET",
        headers: { "Content-Type": "application/html" },
      })
        .then(resp => resp.text())
        .then(data => this.update_with_fetched_data(data));
    }
  }
}
