import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["location"];
  connect() {}
  update_with_fetched_data(fetchedData) {
    var directSection = document.getElementById(this.data.get("tag"));
    directSection.innerHTML = fetchedData;
  }
  get_data() {
    fetch(`${this.data.get("url")}?tloc=${this.locationTarget.value}`, {
      method: "GET",
      headers: { "Content-Type": "application/html" },
    })
      .then(resp => resp.text())
      .then(data => this.update_with_fetched_data(data));
  }
}
