import { Controller } from "stimulus";

export default class extends Controller {
  testIds = [];
  testName = [];
  secondName = [];
  selectedTests = [];

  initialize() {
    var tests = JSON.parse(
      document.getElementById(`${this.data.get("datasetElementId")}`).dataset
        .testDetails
    );
    var existingTests = JSON.parse(
      document.getElementById("dataset_associated_elements_id").dataset
        .testDetails
    );
    tests.map(test => {
      this.testIds.push(test.id);
      this.testName.push(test.test_name.toUpperCase());
      this.secondName.push(test.test_short_name_first.toUpperCase());
    });
    if (existingTests.length > 0) {
      this.addExistingTests(existingTests);
    }
    this.updateView();
    this.updateForm();
  }

  addToProfile(event) {
    var addTestId = parseInt(event.currentTarget.dataset.id);
    console.log(addTestId);
    if (!this.checkIfExists(addTestId)) {
      this.selectedTests.push({
        id: addTestId,
        index: this.testIds.findIndex(id => id === addTestId),
      });
      this.updateView();
      this.updateForm();
    } else {
      alert("A test can only be added once in a profile");
    }
  }

  removeFromProfile(event) {
    this.selectedTests.splice(event.currentTarget.dataset.index, 1);
    this.updateView();
    this.updateForm();
  }

  updateView() {
    var innerContent = "";
    this.selectedTests.map((test, index) => {
      var htmlData =
        `<div class="btn-group mr-2">` +
        `<button type="button" class="btn btn-light">${
          this.secondName[test.index]
        }</button>` +
        `<button type="button" class="ml-1 bg-secodary close"` +
        `data-action="profile-selector#removeFromProfile" data-index=${index}>` +
        `&times;</button></div>`;
      innerContent = innerContent + htmlData;
    });
    document.getElementById("show_selected_tests").innerHTML = innerContent;
    this.updateSubmitButton();
  }

  updateForm() {
    var innerContent = "";
    this.selectedTests.map((test, index) => {
      var htmlData = `<input type="text" name="test_codes[${index}]" value=${test.id}>`;
      innerContent += htmlData;
    });
    document.getElementById("hidden_test_codes").innerHTML = innerContent;
  }

  checkIfExists(id) {
    return !!this.selectedTests.find(test => test.id === id);
  }

  updateSubmitButton() {
    if (this.selectedTests.length > 0) {
      document.getElementById("submit-button").disabled = false;
    } else {
      document.getElementById("submit-button").disabled = true;
    }
  }

  addExistingTests(tests) {
    tests.map(test => {
      this.selectedTests.push({
        id: test.id,
        index: this.testIds.findIndex(id => id === test.id),
      });
    });
  }
}
