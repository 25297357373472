import { Controller } from "stimulus";

export default class extends Controller {
  ids = [];
  test_name = [];
  second_name = [];
  third_name = [];

  initialize() {
    var tests = JSON.parse(
      document.getElementById(`${this.data.get("datasetElementId")}`).dataset
        .testDetails
    );
    tests.map(test => {
      this.ids.push(test.id);
      if (this.data.get("datasetPrefix") === "path") {
        // This can be improved for more flexibility, plan when possible
        this.test_name.push(test.test_name.toUpperCase());
        this.second_name.push(test.test_short_name_first.toUpperCase());
        this.third_name.push(test.test_short_name_second.toUpperCase());
      } else if (this.data.get("datasetPrefix") === "xray") {
        this.test_name.push(test.test_name.toUpperCase());
        this.second_name.push(test.short_name.toUpperCase());
      } else if (this.data.get("datasetPrefix") == "test_profile") {
        this.test_name.push(test.name.toUpperCase());
        this.second_name.push(test.short_name.toUpperCase());
      }
      return true;
    });
  }

  // we will have to change the string from this.data.get("datasetName")
  query(event) {
    var searchString = event.target.value.trim().toUpperCase();
    this.showThese(
      this.findIndexes(searchString),
      this.data.get("datasetPrefix")
    );
  }

  findIndexes(searchString) {
    return this.ids.filter(
      (el, index) =>
        this.test_name[index].includes(searchString) ||
        (this.second_name.length > 0
          ? this.second_name[index].includes(searchString)
          : false) ||
        (this.third_name.length > 0
          ? this.third_name[index].includes(searchString)
          : false)
    );
  }

  showThese(idArray, prefix) {
    // the case of no data is already addressed by default behavior of filter function
    this.ids.forEach(id => {
      if (idArray.includes(id)) {
        document.getElementById(`${prefix}_${id}`).style.visibility = "visible";
      } else {
        document.getElementById(`${prefix}_${id}`).style.visibility =
          "collapse";
      }
    });
  }
}
