import { Controller } from "stimulus";

export default class extends Controller {
  doctorList = [];
  static targets = ["mso"];

  update_with_fetched_data(fetchedData) {
    var doctorsSection = document.getElementById(this.data.get("tag"));
    var selectTag = doctorsSection.getElementsByTagName("select")[0];
    selectTag.innerHTML = "";
    selectTag.innerHTML = '<option value="">Select a doctor</option>';
    fetchedData.forEach(element => {
      let optionElement = document.createElement("option");
      optionElement.textContent = element.name;
      optionElement.value = element.id;
      selectTag.appendChild(optionElement);
    });
    doctorsSection.style = "display:block;";
    document.getElementById(this.data.get("tag")).style = "display:block;";
  }

  addDataToElement(data, tag_id) {
    let element = document.createElement("div");
    element.style.display = "none;";
    element.setAttribute("id", tag_id);
    element.dataset.object = JSON.stringify(data);
    if (document.getElementById(tag_id)) {
      document.getElementById(tag_id).remove();
    }
    document.body.appendChild(element);
    this.update_with_fetched_data(data);
  }

  get_data() {
    fetch(`${this.data.get("url")}${this.msoTarget.value}`, {
      // credentials: "same-origin",
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then(resp => resp.json())
      .then(data => this.addDataToElement(data, "mso-doctors-data"));
  }
}
